import { Middleware } from '@nuxt/types';
import { Logger } from '~/helpers/logger';
import { CustomRoutableInterface } from '~/modules/GraphQL/custom-types';
import { usePageStore } from '~/stores/page';

const urlResolverMiddleware: Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path } = context.route;

  const clearUrl = path
    .replace(/[a-z]+\/[cp|]\//gi, '')
    .replace(`/${context.i18n.locale}`, '')
    .replace(/^\/+/, ''); // This will remove any leading slashes

  Logger.debug('middleware/url-resolver', clearUrl);

  const response = await context.app.$vsf.$magento.api.route(clearUrl);
  const { data, errors } = response;

  Logger.debug('middleware/url-resolver/result', { data, errors });

  const results: CustomRoutableInterface | null = data?.route ?? null;

  if (!results || errors?.length) context.error({ statusCode: 404 });

  pageStore.$patch((state) => {
    state.routeData = results;
    state.parentRouteData = null;
  });
};

export default urlResolverMiddleware;
