











































































































import { computed, defineComponent, useContext, useFetch, watch } from '@nuxtjs/composition-api';
import { SfButton, SfLink, SfList, SfSidebar } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import CustomerService from '~/components/Header/CustomerService.vue';
import { useAlertBarOffset, useUiState, useUser } from '~/composables';
import { useSidebarLinkGroups } from '~/modules/customer/pages/MyAccount/useSidebarLinkGroups';

export default defineComponent({
  components: {
    SfSidebar,
    SfList,
    SfLink,
    SfButton,
    CustomerService,
    SvgImage,
  },
  setup() {
    const { logoutUser } = useSidebarLinkGroups();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { isMobileMenuOpen, toggleMobileMenu, toggleSearchBar } = useUiState();
    const {
      route,
      app: { i18n },
    } = useContext();
    const accountLinkName = computed(() => {
      if (user.value?.firstname && user.value?.lastname) {
        return `${user.value.firstname} ${user.value.lastname}`;
      }
      return user.value?.email ?? i18n.t('My Account');
    });
    useAlertBarOffset();

    const links = [
      { id: 1, name: 'This is how it works', url: '/sa-fungerar-det' },
      { id: 2, name: 'About us', url: '/om-oss' },
      { id: 3, name: 'Where you can find us', url: '/provtagningsstallen' },
      { id: 4, name: 'Fear of needles', url: '/stickradd' },
    ];

    const openSearch = () => {
      toggleMobileMenu();
      toggleSearchBar();
    };

    const onClickItem = (link: string) => {
      if (link === route.value.path) {
        toggleMobileMenu();
      }
    };

    useFetch(async () => {
      if (isAuthenticated.value && !user.value) {
        await loadUser();
      }
    });

    watch(
      () => route.value,
      () => {
        if (isMobileMenuOpen.value) {
          toggleMobileMenu();
        }
      },
    );

    return {
      links,
      accountLinkName,
      isAuthenticated,
      toggleMobileMenu,
      openSearch,
      logoutUser,
      onClickItem,
    };
  },
});
