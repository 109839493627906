import { PlaceOrderMutation } from '@vue-storefront/magento-api';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { UseContextReturn } from '~/types/core';

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, clinicId: string): Promise<PlaceOrderOutput | null> => {
    const { data }: { data: PlaceOrderMutation } = await context.app.$vsf.$magento.api.customQuery({
      query: `  mutation placeOrder($input: PlaceOrderInput) {
        placeOrder(input: $input) {
          order {
            order_number
          }
        }
      }`,
      queryVariables: { input: { cart_id: cartId, clinic_id: clinicId } },
    });

    return data?.placeOrder ?? null;
  },
};
