import type { Ref } from '@nuxtjs/composition-api';
import { computed, readonly, ref, useContext, useRouter } from '@nuxtjs/composition-api';
import { useApi, useWishlist } from '~/composables';
import { useUiNotification } from '~/composables/useUiNotification';
import mask from '~/composables/utils/mask';
import { Logger } from '~/helpers/logger';
import {
  CustomCustomer,
  CustomUseUserCodeLoginParams,
  CustomUseUserLoginParams,
  CustomUseUserRegisterParams,
  CustomUseUserSsnLoginParams,
} from '~/modules/GraphQL/custom-types';
import { useCart } from '~/modules/checkout/composables/useCart';
import { generateUserData } from '~/modules/customer/helpers/generateUserData';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import customerGql from '../../queries/customer.gql';
import generateCustomerTokenWithAuthCodeGql from '../../queries/generateCustomerTokenWithAuthCode.gql';
import sendAuthCodeGql from '../../queries/sendAuthCode.gql';
import updateCustomerGql from '../../queries/updateCustomer.gql';
import { GenerateCustomerTokenWithAuthCodeQuery, SendAuthCodeQuery } from '../types';
import useBankId from '../useBankId';
import type { UseUserChangePasswordParams, UseUserErrors, UseUserInterface, UseUserLogoutParams, UseUserUpdateUserParams } from './useUser';

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function useUser(): UseUserInterface {
  const { query } = useApi();
  const customerStore = useCustomerStore();
  const { cancelBankIdProcess } = useBankId();
  // @ts-ignore
  const { app, localeRoute } = useContext();
  const { setCart } = useCart();
  const { setWishlist } = useWishlist();
  const { send: sendNotification } = useUiNotification();
  const { startBankId, removeOrderReference } = useBankId();
  const router = useRouter();
  const loading: Ref<boolean> = ref(false);
  const getCodeLoading: Ref<boolean> = ref(false);
  const codeReceived: Ref<boolean> = ref(false);
  const errorsFactory = (): UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
    ssnLogin: null,
    codeLogin: null,
  });
  const error: Ref = ref(errorsFactory());

  const resetCodeReceived = () => {
    codeReceived.value = false;
  };

  const setUser = (newUser: CustomCustomer) => {
    customerStore.user = newUser;
    Logger.debug('useUserFactory.setUser', newUser);
  };

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  const updateCustomerEmail = async (credentials: { email: string; password: string }): Promise<void> => {
    const { errors } = await app.context.$vsf.$magento.api.updateCustomerEmail(credentials);

    if (errors) {
      throw errors.map((e) => e.message).join(',');
    }
  };

  // eslint-disable-next-line consistent-return
  const updateUser = async ({ user: providedUser, customQuery, customHeaders }: UseUserUpdateUserParams) => {
    Logger.debug('[Magento] Update user information', { providedUser, customQuery, customHeaders });
    resetErrorValue();

    try {
      loading.value = true;
      const { email: oldEmail } = customerStore.user;
      const { email, password, ...updateData } = providedUser;

      const { socialSecurityNumber, ...userData } = generateUserData(updateData);

      if (email && email !== oldEmail) {
        await updateCustomerEmail({
          email,
          password,
        });
      }

      const { data, errors } = await app.context.$vsf.$magento.api.customQuery({ query: updateCustomerGql, queryVariables: { input: userData } });
      Logger.debug('[Result]:', { data });

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        Logger.error(allErrorMessages);
        error.value.updateUser = allErrorMessages;
      }

      customerStore.user = data?.updateCustomerV2?.customer || {};
      error.value.updateUser = null;
    } catch (err) {
      error.value.updateUser = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
  };

  const logout = async ({ customQuery = {}, customHeaders = {} }: UseUserLogoutParams = {}) => {
    Logger.debug('[Magento] useUserFactory.logout');
    resetErrorValue();

    try {
      const apiState = app.context.$vsf.$magento.config.state;

      await app.context.$vsf.$magento.api.revokeCustomerToken(customQuery, customHeaders);

      apiState.removeCustomerToken();
      apiState.removeCartId();
      setCart(null);
      setWishlist({});
      customerStore.setIsLoggedIn(false);
      error.value.logout = null;
      customerStore.user = null;
      sendNotification({
        id: Symbol('logout_success'),
        message: app.i18n.t('Logout successful') as string,
        persist: false,
        title: 'logout success',
        type: 'success',
        icon: 'check',
      });
    } catch (err) {
      error.value.logout = err;
      Logger.error('useUser/logout', err);
      sendNotification({
        id: Symbol('logout_fail'),
        message: app.i18n.t('Logout failed') as string,
        persist: false,
        title: 'logout success',
        type: 'danger',
        icon: 'error',
      });
    }
  };

  const load = async () => {
    Logger.debug('[Magento] useUser.load');
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      if (!apiState.getCustomerToken()) {
        return null;
      }
      try {
        const { data } = await app.context.$vsf.$magento.api.customQuery({ query: customerGql, queryVariables: {} });

        Logger.debug('[Result]:', { data });

        customerStore.user = data?.customer ?? {};
      } catch {
        // eslint-disable-next-line no-void
        // @ts-ignore
        await logout();
      }
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useUser/load', err);
    } finally {
      loading.value = false;
    }

    return customerStore.user;
  };

  const loginWithPassword = async ({ user: providedUser, customQuery, customHeaders }: CustomUseUserLoginParams): Promise<string> => {
    const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken(
      {
        email: providedUser.email,
        password: providedUser.password,
        recaptchaToken: providedUser.recaptchaToken,
      },
      customQuery || {},
      customHeaders || {},
    );
    Logger.debug('[Result]:', { data });

    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      errors.forEach((registerError, i) =>
        sendNotification({
          icon: 'error',
          id: Symbol(`registration_error-${i}`),
          message: registerError.message,
          persist: true,
          title: 'Registration error',
          type: 'danger',
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      throw new Error(joinedErrors);
    }
    return data.generateCustomerToken.token;
  };

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const login = async ({
    user: providedUser,
    isWithBankId,
    isOnAnotherDevice,
    customQuery,
    customHeaders,
  }: CustomUseUserLoginParams): Promise<void> => {
    Logger.debug('[Magento] useUser.login', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      let token = '';
      if (isWithBankId) {
        // eslint-disable-next-line
        if (isOnAnotherDevice) {
          token = await startBankId({ socialSecurityNumber: providedUser.socialSecurityNumber, isOnAnotherDevice: true, isLogin: true });
        } else {
          token = await startBankId({ socialSecurityNumber: providedUser.socialSecurityNumber, isOnAnotherDevice: false, isLogin: true });
        }
      } else {
        token = await loginWithPassword({ user: providedUser, customQuery, customHeaders });
      }

      customerStore.setIsLoggedIn(true);
      apiState.setCustomerToken(token);

      // merge existing cart with customer cart
      // todo: move this logic to separate method
      const currentCartId = apiState.getCartId();
      const cart = await app.context.$vsf.$magento.api.customerCart();
      const newCartId = cart.data.customerCart.id;

      try {
        if (newCartId && currentCartId && currentCartId !== newCartId) {
          const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts({
            sourceCartId: currentCartId,
            destinationCartId: newCartId,
          });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(dataMergeCart.mergeCarts);

          apiState.setCartId(dataMergeCart.mergeCarts.id);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(cart.data.customerCart);
        }
      } catch {
        // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
        // It can be removed when Magento 2.4.5 will be release
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCart(cart.data.customerCart);
      }
      error.value.login = null;
      sendNotification({
        id: Symbol('login_success'),
        message: app.i18n.t('Login successful') as string,
        persist: false,
        title: 'login success',
        type: 'success',
        icon: 'check',
      });
      await load();
      router.push(localeRoute({ name: 'reports' }));
    } catch (err) {
      cancelBankIdProcess();
      error.value.login = err;
      Logger.error('useUser/login', err);
      sendNotification({
        id: Symbol('login_error'),
        message: typeof err.message === 'string' ? err.message : 'Error',
        persist: false,
        title: 'login error',
        type: 'danger',
        icon: 'error',
      });
    } finally {
      removeOrderReference();
      loading.value = false;
    }
  };

  // eslint-disable-next-line
  const register = async ({ user: providedUser, isOnAnotherDevice }: CustomUseUserRegisterParams): Promise<void> => {
    Logger.debug('[Magento] useUser.register', providedUser);
    resetErrorValue();

    try {
      loading.value = true;

      const { email, password, socialSecurityNumber, recaptchaToken, ...baseData } = generateUserData(providedUser);

      // eslint-disable-next-line
      if (isOnAnotherDevice) {
        await startBankId({ email, socialSecurityNumber, isOnAnotherDevice: true, ...baseData });
      } else {
        await startBankId({ email, socialSecurityNumber, isOnAnotherDevice: false, ...baseData });
      }

      error.value.register = null;
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        customer: { customer_create_account_confirm },
      } = app.context.$vsf.$magento.config;

      sendNotification({
        id: Symbol('registration_success'),
        message: app.i18n.t('Account created') as string,
        persist: true,
        title: 'registration success',
        type: 'success',
        icon: 'check',
      });

      if (customer_create_account_confirm) {
        sendNotification({
          id: Symbol('registration_confirmation'),
          message: app.i18n.t('You must confirm your account. Please check your email for the confirmation link.') as string,
          persist: false,
          title: 'Registration confirmation',
          type: 'success',
          icon: 'check',
        });
      }
      router.push(localeRoute({ name: 'login' }));
    } catch (err) {
      cancelBankIdProcess();
      error.value.register = err;
      Logger.error('useUser/register', err);
      sendNotification({
        id: Symbol('registration_error'),
        message: typeof err.message === 'string' ? err.message : 'Error',
        persist: false,
        title: 'Registration error',
        type: 'danger',
        icon: 'error',
      });
    } finally {
      removeOrderReference();
      loading.value = false;
    }
  };
  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const ssnLogin = async ({ socialSecurityNumber }: CustomUseUserSsnLoginParams) => {
    const variables = {
      input: {
        social_security_number: socialSecurityNumber,
      },
    };

    try {
      getCodeLoading.value = true;
      const { errors } = await query<SendAuthCodeQuery>(sendAuthCodeGql, variables); // update query type
      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) =>
          sendNotification({
            icon: 'error',
            id: Symbol(`registration_error-${i}`),
            message: registerError.message,
            persist: true,
            title: 'Registration error',
            type: 'danger',
          }),
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      } else {
        codeReceived.value = true;
      }
    } catch (err) {
      sendNotification({
        id: Symbol('registration_error'),
        message: typeof err.message === 'string' ? err.message : 'Error',
        persist: false,
        title: 'Registration error',
        type: 'danger',
        icon: 'error',
      });
    } finally {
      getCodeLoading.value = false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const codeLogin = async ({ authCode, socialSecurityNumber }: CustomUseUserCodeLoginParams) => {
    const variables = {
      input: {
        auth_code: authCode,
        social_security_number: socialSecurityNumber,
      },
    };

    try {
      loading.value = true;
      const { data, errors } = await query<GenerateCustomerTokenWithAuthCodeQuery>(generateCustomerTokenWithAuthCodeGql, variables); // update query type

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) =>
          sendNotification({
            icon: 'error',
            id: Symbol(`registration_error-${i}`),
            message: registerError.message,
            persist: true,
            title: 'Registration error',
            type: 'danger',
          }),
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      } else {
        const { token } = data.generateCustomerTokenWithAuthCode;
        const apiState = app.context.$vsf.$magento.config.state;
        codeReceived.value = true;
        customerStore.setIsLoggedIn(true);
        apiState.setCustomerToken(token);
        router.push(localeRoute({ name: 'reports' }));
      }
    } catch (err) {
      sendNotification({
        id: Symbol('registration_error'),
        message: typeof err.message === 'string' ? err.message : 'Error',
        persist: false,
        title: 'Registration error',
        type: 'danger',
        icon: 'error',
      });
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams) => {
    Logger.debug('[Magento] useUser.changePassword', { currentPassword: mask(params.current), newPassword: mask(params.new) });
    resetErrorValue();

    try {
      loading.value = true;

      const { data, errors } = await app.context.$vsf.$magento.api.changeCustomerPassword(
        {
          currentUser: customerStore.user,
          currentPassword: params.current,
          newPassword: params.new,
        },
        params.customQuery,
        params?.customHeaders,
      );

      let joinedErrors = null;

      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
      }

      Logger.debug('[Result] ', { data });

      customerStore.user = data?.changeCustomerPassword;
      error.value.changePassword = joinedErrors;
    } catch (err) {
      error.value.changePassword = err;
      Logger.error('useUser/changePassword', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    setUser,
    updateUser,
    register,
    login,
    logout,
    changePassword,
    load,
    ssnLogin,
    codeLogin,
    resetCodeReceived,
    loading: readonly(loading),
    getCodeLoading: readonly(getCodeLoading),
    codeReceived: readonly(codeReceived),
    error: readonly(error),
    user: computed(() => customerStore.user),
    isAuthenticated: computed(() => customerStore.isLoggedIn),
  };
}

export default useUser;
export * from './useUser';
