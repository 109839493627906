






import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { useApi } from '~/composables';
import { StaticText } from '~/modules/GraphQL/custom-types';
import getAlertBarTextGql from '~/modules/GraphQL/queries/getAlertBarText.gql';
import HTMLContent from '../HTMLContent.vue';

export default defineComponent({
  name: 'AlertBar',
  components: { HTMLContent },
  setup() {
    const message = ref('');
    const { query } = useApi();
    useFetch(async () => {
      const { data } = await query<StaticText>(getAlertBarTextGql);
      message.value = data.static_text?.text.html || '';
    });
    return {
      message,
    };
  },
});
