import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { UseWindowResize } from './useWindowResize';

export function useWindowResize(): UseWindowResize {
  const isMobile = ref(false);

  const onResize = () => {
    isMobile.value = window.innerWidth < 1024;
  };

  onMounted(() => {
    window.addEventListener('resize', onResize);
    onResize(); // call once initially
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onResize);
  });

  return { isMobile };
}
