export default `
mutation cancelBankIdProcess(
	$order_ref: String!
) {
	cancelBankIdProcess(
		order_ref: $order_ref
	) {
		message
		status
	}
}
`;
