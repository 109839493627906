






















import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';
import { SfProductCard } from '@storefront-ui/vue';
import { useAlertBarOffset, useHoverImage, useImage, useProduct } from '~/composables';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { CustomProduct } from '~/modules/catalog/types';

export default defineComponent({
  name: 'SearchResultsItem',
  components: {
    SfProductCard,
  },
  props: {
    product: {
      type: Object as PropType<CustomProduct>,
    },
  },
  setup() {
    const { imageSizes } = useImage();
    const { getProductPath } = useProduct();
    const { isHovered, hoverImage, unhoverImage } = useHoverImage();
    useAlertBarOffset();

    return {
      isHovered,
      productGetters,
      imageSizes,
      getProductPath,
      hoverImage,
      unhoverImage,
    };
  },
});
