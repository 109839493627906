import { Middleware } from '@nuxt/types';
import { Logger } from '~/helpers/logger';
import { CustomRoutableInterface } from '~/modules/GraphQL/custom-types';
import { usePageStore } from '~/stores/page';

const packageMiddleware: Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path } = context.route;

  const rootPackageUrl = 'analyspaket';
  const clearUrl = path
    .replace(/[a-z]+\/[cp|]\//gi, '')
    .replace(`/${context.i18n.locale}`, '')
    .replace(`${rootPackageUrl}`, '');

  Logger.debug('middleware/url-resolver', clearUrl);

  const response = await context.app.$vsf.$magento.api.route(clearUrl);
  const { data } = response;
  let { errors } = response;

  Logger.debug('middleware/url-resolver/result', { data, errors });

  const results: CustomRoutableInterface | null = data?.route ?? null;

  if (!results || errors?.length) context.error({ statusCode: 404 });

  // TODO: Remove this check when the backend removes '.html' from the expected route
  // Check the analysis_type of the route data. Because we are moving the simple analysis product to /analys/:slug
  if (results?.analysis_type === 'SIMPLE') {
    // If it is 'SIMPLE', redirect to the error page
    errors = [{ statusCode: 404 } as any];
    context.redirect('/error');
    return;
  }

  pageStore.$patch((state) => {
    state.routeData = results;
    state.parentRouteData = null;
  });
};

export default packageMiddleware;
