export default `query customer {
  customer {
    date_of_birth
    default_billing
    default_shipping
    email
    firstname
    is_subscribed
    lastname
    gender
    phone_number
    middlename
    prefix
    suffix
    taxvat
    social_security_number
    registered_with_bank_id
    addresses {
      city
      country_code
      default_billing
      default_shipping
      extension_attributes {
        attribute_code
        value
      }
      firstname
      id
      lastname
      postcode
      prefix
      region {
        region_code
        region_id
        region
      }
      street
      suffix
      telephone
      vat_id
    }
    is_subscribed
  }
}
`;
