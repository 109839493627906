export default `
mutation startBankIdProcess(
	$social_security_number: String
	$bankid_type: BankIDTypeEnum!
	$device: DeviceEnum!
) {
	startBankIdProcess(
		social_security_number: $social_security_number
		bankid_type: $bankid_type
		device: $device
	) {
		order_ref
		auto_start_token
		qr_auth_code
	}
}
`;
