




















import { PropType, defineComponent, ref } from '@nuxtjs/composition-api';
import { SfAccordion, SfLink, SfList } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';

export default defineComponent({
  name: 'HeaderNavigationCategory',
  components: {
    SfList,
    SfAccordion,
    SfLink,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    links: {
      type: Array as PropType<
        {
          label: string;
          url: string;
          icon?: string;
        }[]
      >,
      required: true,
    },
  },
  setup() {
    const isAccordionOpen = ref(false);

    const toggleAccordion = () => {
      isAccordionOpen.value = !isAccordionOpen.value;
    };

    const closeAccordion = () => {
      if (isAccordionOpen.value) {
        isAccordionOpen.value = false;
      }
    };

    return {
      isAccordionOpen,
      toggleAccordion,
      closeAccordion,
    };
  },
});
