import UAParser from 'ua-parser-js';

export const getDeviceType = (): string => {
  const parser = new UAParser();
  const result = parser.getResult();
  const deviceType = result.device.type;
  return deviceType === 'mobile' || deviceType === 'tablet' ? 'MOBILE' : 'DESKTOP';
};

const createMobileReturnUrl = (uaParserResult: any) => {
  const isChrome = uaParserResult.browser.name.match('Chrome');
  const isFirefox = uaParserResult.browser.name.match('Mozilla');

  if (isChrome) {
    return 'googlechrome://';
  }
  if (isFirefox) {
    return 'firefox://';
  }
  return 'safari://'; // will make the user remain in the bankid app
};

export const getStartBankIdUrl = (autoStartToken: string) => {
  const parser = new UAParser();
  const result = parser.getResult();
  const deviceType = result.device.type;
  const isMobile = deviceType === 'mobile' || deviceType === 'tablet';

  return `bankid:///?autostarttoken=${autoStartToken}&redirect=${isMobile ? createMobileReturnUrl(result) : null}`;
};
