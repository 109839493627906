import { readonly, ref, useContext, useRoute } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { getProductDetailsCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductDetailsCommand';
import { getProductListCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductListCommand';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import { CustomProductInterface } from '~/modules/catalog/types';
import type { ProductDetails, ProductList, UseProductErrors, UseProductInterface } from './useProduct';

/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string): UseProductInterface {
  const loading = ref(false);
  const error = ref<UseProductErrors>({
    getProductList: null,
    getProductDetails: null,
  });

  const context = useContext();

  const getIsPackage = (product: CustomProductInterface) => {
    switch (product.analysis_type) {
      case 'SIMPLE':
        return false;
      case 'PACKAGE':
        return true;
      default:
        throw new Error('Invalid product analysis type');
    }
  };

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    Logger.debug(`useProduct/${id}/getProductList`, searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;
      products = await getProductListCommand.execute(context, searchParams);
      error.value.getProductList = null;
    } catch (err) {
      error.value.getProductList = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductDetails = async (searchParams: GetProductSearchParams): Promise<ProductDetails | null> => {
    Logger.debug(`useProduct/${id}/getProductDetails`, searchParams);
    let products: ProductDetails = null;

    try {
      loading.value = true;
      products = await getProductDetailsCommand.execute(context, searchParams);
      error.value.getProductDetails = null;
    } catch (err) {
      error.value.getProductDetails = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getBasePath = (product: CustomProductInterface) => {
    if (!product) return '/';
    return `/${product.url_key}`;
  };

  const getPackagePath = (product: CustomProductInterface) => {
    if (!product) return '/';
    const packageUrlRoot = '/analyspaket';
    return `${packageUrlRoot}${getBasePath(product)}`;
  };

  const getAnalysisPath = (product: CustomProductInterface) => {
    const route = useRoute();

    const productPath = getBasePath(product);
    const packageSlug = route.value.params?.parentSlug;
    const packageUrl = packageSlug ? `/${packageSlug}` : '';
    const baseUrl = '/analys';

    return `${packageUrl}${baseUrl}${productPath}`;
  };

  const getProductPath = (product: CustomProductInterface) => (getIsPackage(product) ? getPackagePath(product) : getAnalysisPath(product));

  return {
    getIsPackage,
    getProductList,
    getProductDetails,
    getBasePath,
    getProductPath,
    getPackagePath,
    getAnalysisPath,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useProduct';
export default useProduct;
