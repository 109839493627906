










import { defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import SvgImage from '../General/SvgImage.vue';

export default defineComponent({
  name: 'CustomerService',
  components: { SvgImage, SfLink },
});
