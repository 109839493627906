export default `
query getPackagesList(
	$search: String = ""
	$filter: ProductAttributeFilterInput
	$pageSize: Int = 9999
	$currentPage: Int = 1
	$sort: ProductAttributeSortInput
) {
	products(
		search: $search
		filter: $filter
		sort: $sort
		pageSize: $pageSize
		currentPage: $currentPage
	) {
		items {
			name
			sku
			analysis {
				default_analysis {
					name
					available_in_all_clinics
					laboratory_id
					stock_status
					only_x_left_in_stock
					url_key
					analysis_type
					sku
					uid
					thumbnail {
						url
						position
						disabled
						label
					}
					hover_image {
						url
						position
						disabled
						label
					}
					price_range {
						maximum_price {
							final_price {
								currency
								value
							}
							regular_price {
								currency
								value
							}
						}
						minimum_price {
							final_price {
								currency
								value
							}
							regular_price {
								currency
								value
							}
						}
					}
					small_image {
						url
						position
						disabled
						label
					}
					image {
						url
						position
						disabled
						label
					}
					additional_info {
						html
					}
					short_description {
						html
					}
				}
				total_count
				children {
					name
					uid
					include_in_menu
					image
					products {
						items {
							name
							sku
							url_key
							analysis_type
							available_in_all_clinics
						}
					}
				}
			}
		}
	}
}

`;
