



































import { defineComponent, nextTick, onMounted, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { SfButton, SfSearchBar } from '@storefront-ui/vue';
import { debounce } from 'lodash-es';
import SvgImage from '~/components/General/SvgImage.vue';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { useApi, useUiState } from '~/composables';
import getSearchedProductsByTypeGql from '~/modules/catalog/product/queries/getSearchedProductsByType.gql';
import { SearchResultsByType, SearchResultsByTypeQueryData } from '~/modules/catalog/types';
import SearchResults from './SearchResults.vue';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
    SearchResults,
  },
  directives: { clickOutside },
  props: {
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
  },
  emits: ['set-is-open'],
  setup(props) {
    const term = ref('');
    const route = useRoute();
    const { query } = useApi();
    const searchBar = ref<any | null>(null);
    const searchBarInput = ref<HTMLInputElement | null>(null);
    const searchResults = ref<any | null>(null);
    const { isSearchVisible, toggleSearchBar } = useUiState();
    const productSearchResults = ref<SearchResultsByType | null>(null);

    const closeSearch = (event?: MouseEvent) => {
      const closeTriggerElement = event?.target as HTMLElement;
      if (searchResults.value?.$el.contains(closeTriggerElement)) return;

      if (isSearchVisible.value) {
        toggleSearchBar();
      }

      term.value = '';
      productSearchResults.value = null;
    };

    const rawHandleSearch = async (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length === 0) {
        productSearchResults.value = null;
        return;
      }
      if (term.value.length < props.minTermLen) return;

      const { data } = await query<SearchResultsByTypeQueryData>(getSearchedProductsByTypeGql, { search: term.value });
      productSearchResults.value = data.products_by_type;
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 500);

    const handleKeydownEnter = (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      rawHandleSearch(searchTerm);
    };

    const onClickOutside = (event: MouseEvent) => {
      if (!isSearchVisible.value) return;
      const targetElement = event.target as HTMLElement;
      // Check if the target element or any of its ancestors has the 'search-icon' class
      if (!targetElement.closest('.search-icon') && !targetElement.closest('.search')) {
        closeSearch();
      }
    };

    watch(route, () => {
      closeSearch();
    });

    watch(isSearchVisible, (newValue) => {
      nextTick(() => {
        if (newValue) {
          searchBarInput.value?.focus();
        }
      });
    });

    onMounted(() => {
      searchBarInput.value = searchBar.value.$el.querySelector('input');
    });

    return {
      closeSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
      isSearchVisible,
      onClickOutside,
      searchBar,
      searchResults,
      productSearchResults,
    };
  },
});
