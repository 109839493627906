import { Middleware } from '@nuxt/types';
import { CustomRoutableInterface } from '~/modules/GraphQL/custom-types';
import { useAnalysesStore } from '~/modules/catalog/product/stores/analyses';
import { usePageStore } from '~/stores/page';

/* eslint-disable unicorn/prefer-ternary */

// Navigation Structure
// /analyses : Homepage > Analyses
// /analyses/analysis-url : Homepage > Analyses > AnalysisName
// /product-url/analyses : Homepage > ProductName > Analyses
// /product-url/analyses/analysis-url : Homepage > ProductName > ProductName Analyses > AnalysisName

interface RouteUrl {
  parentRoute?: string;
  route?: string;
}

const urlResolverMiddleware: Middleware = async (context) => {
  const listingPageRoute = 'analys';
  const pageStore = usePageStore();
  const analysesStore = useAnalysesStore();
  const { path } = context.route;
  const validLocales = ['en', 'sv'];

  const parts = path.split('/'); // ['', 'locale', ...]
  if (validLocales.includes(parts[1])) {
    parts.splice(1, 1); // remove locale if it exists
  }
  const firstPart = parts[1];
  const secondPart = parts[2];
  const thirdPart = parts[3];

  // This is used to reset the store when navigating from a product to another product
  // If new ways of navigating to the analyses page are added, this should be updated
  if (firstPart === listingPageRoute && !secondPart) {
    // List of all analyses (/analys)
    analysesStore.reset();
    pageStore.$patch((state) => {
      state.routeData = null;
      state.parentRouteData = null;
    });
    return;
  }

  let routeUrls: RouteUrl = {};

  if (thirdPart) {
    // Case for route of specific analysis from a package's list (/product-url/analyses/analysis-url)
    routeUrls = { parentRoute: firstPart, route: thirdPart };
  } else if (firstPart === listingPageRoute) {
    // We can also have (/analyses/analysis-url)
    routeUrls = { route: secondPart };
  } else if (secondPart === listingPageRoute) {
    // (/product-url/analyses)
    routeUrls = { parentRoute: firstPart };
  }

  if (routeUrls.parentRoute && routeUrls.parentRoute !== listingPageRoute && !routeUrls.route) {
    // List of all analyses belonging to a given package (/analys)
    pageStore.$patch((state) => {
      state.routeData = null;
    });
  }

  if (routeUrls.parentRoute && pageStore.parentRouteData?.slug !== routeUrls.parentRoute) {
    const { data, errors } = await context.app.$vsf.$magento.api.route(routeUrls.parentRoute);

    const results: CustomRoutableInterface | null = data?.route ?? null;

    if (!results || errors?.length) context.error({ statusCode: 404 });

    if (pageStore.parentRouteData?.sku !== results?.sku) {
      analysesStore.reset();
    }

    pageStore.$patch((state) => {
      state.parentRouteData = { ...results, slug: routeUrls.parentRoute };
      state.routeData = null;
    });
  } else if (!routeUrls.parentRoute) {
    pageStore.$patch((state) => {
      state.parentRouteData = null;
      state.routeData = null;
    });
  }

  if (routeUrls.route) {
    const { data, errors } = await context.app.$vsf.$magento.api.route(routeUrls.route);

    const results: CustomRoutableInterface | null = data?.route ?? null;

    if (!results || errors?.length) context.error({ statusCode: 404 });

    pageStore.$patch((state) => {
      state.routeData = results;
    });
  }
};

export default urlResolverMiddleware;
