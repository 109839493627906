














































































































import { SfBadge, SfButton, SfHeader, SfLink, SfOverlay } from '@storefront-ui/vue';

import { computed, defineComponent, onBeforeMount, onMounted, ref, useContext, useRoute, watch } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import HeaderLogo from '~/components/HeaderLogo.vue';
import { useUiHeader, useUiHelpers, useUiState } from '~/composables';
import type { CategoryTree } from '~/modules/GraphQL/types';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { useSidebarLinkGroups } from '~/modules/customer/pages/MyAccount/useSidebarLinkGroups';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import CustomerService from './Header/CustomerService.vue';
import HeaderNavigationButton from './Header/Navigation/header/HeaderNavigationButton.vue';
import HeaderNavigationCategory from './Header/Navigation/header/HeaderNavigationCategory.vue';
import SearchBar from './Header/SearchBar/SearchBar.vue';
import MobileMenuSidebar from './MobileMenuSidebar.vue';

export default defineComponent({
  components: {
    SfLink,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    CustomerService,
    MobileMenuSidebar,
    SearchBar,
    HeaderNavigationCategory,
    HeaderNavigationButton,
  },
  setup() {
    const route = useRoute();
    const { app } = useContext();
    const { isScrollingDown } = useUiHeader();
    const { isSearchVisible, toggleCartSidebar, toggleWishlistSidebar, toggleMobileMenu, toggleSearchBar, isMobileMenuOpen } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();

    const { isAuthenticated, user, load: loadUser } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { logoutUser } = useSidebarLinkGroups();

    const accountName = computed(() => {
      if (user.value?.firstname && user.value?.lastname) {
        return `${user.value.firstname} ${user.value.lastname}`;
      }
      return user.value?.email ?? '';
    });

    const backButtonLink = computed(() => {
      const [routeName, locale] = route.value?.name?.split('___') || [null, null]; // eslint-disable-line @typescript-eslint/no-unused-vars
      switch (routeName) {
        case 'test results':
          return `/reports${route.value.query.customer ? `?customer=${route.value.query.customer}` : ''}`;
        case 'analysis results':
          return `/reports/${route.value.params.reportOrderId}${route.value.query.customer ? `?customer=${route.value.query.customer}` : ''}`;
        default:
          return null;
      }
    });

    const accountLinks = [
      {
        label: 'My profile',
        url: '/customer/my-profile',
        icon: 'arrow_right',
      },
      {
        label: 'My account details',
        url: '/customer/addresses-details',
        icon: 'arrow_right',
      },
      {
        label: 'My orders',
        url: '/customer/order-history',
        icon: 'arrow_right',
      },
      {
        label: 'My reports',
        url: '/reports',
        icon: 'arrow_right',
      },
    ];

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);
    const menuIcon = computed(() => (isMobileMenuOpen.value ? 'close' : 'menu'));
    const activeLink = computed(() => {
      const matchedName = route.value?.matched[0]?.name.split('___')[0];
      switch (matchedName) {
        case 'analysis list':
          return 'analysis list';
        case 'customer':
        case 'login':
        case 'signup':
        case 'forgot password':
          return 'account';
        default:
          if (route.value.params.slug === 'sa-fungerar-det') {
            return 'how-it-works';
          }
          return '';
      }
    });

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = 'profile';
    const categoryTree = ref<CategoryTree[]>([]);
    const customerServiceNumber = '070-555 90 09';

    onBeforeMount(() => {
      categoryTree.value = [];
      if (isAuthenticated.value && user.value === null) {
        loadUser();
      }
    });

    onMounted(async () => {
      const routeName = route.value?.name?.split('___')[0];
      if (routeName !== 'thank-you') {
        await loadCartTotalQty();
      }
      if (app.$device.isDesktop) {
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });

    watch(route, (newValue, oldValue) => {
      const oldRouteName = oldValue?.name?.split('___')[0];
      if (oldRouteName === 'thank-you') {
        loadCartTotalQty();
      }
    });

    return {
      activeLink,
      accountName,
      accountIcon,
      backButtonLink,
      menuIcon,
      accountLinks,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      isAuthenticated,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      customerServiceNumber,
      toggleMobileMenu,
      isMobileMenuOpen,
      toggleSearchBar,
      logoutUser,
      isSearchVisible,
      isScrollingDown,
    };
  },
});
