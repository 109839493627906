export default `mutation createCustomerWithBankID(
	$input: CustomerCreateWithBankIdInput!
	$time: Int
) {
	createCustomerWithBankID(
		input: $input
		time: $time
	) {
		customer {
			firstname
			lastname
			email
			social_security_number
		}
		message
		status
		qr_auth_code
	}
}
`;
