import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { AddProductsToCartOutput, Cart, CartItemInput } from '~/modules/GraphQL/types';
import mutateAddProductsToCartGql from '~/modules/checkout/queries/mutateAddProductsToCart.gql';

/** Params object used to add items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

type AddProductsToCartData = {
  addProductsToCart: AddProductsToCartOutput;
};

export const addItemCommand = {
  execute: async (context: VsfContext, { product, quantity, currentCart }) => {
    Logger.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
    });
    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId();

    if (!product) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    switch (product.__typename) {
      case 'VirtualProduct':
        const virtualCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };
        // @ts-ignore

        const { data }: { data: AddProductsToCartData } = await context.$magento.api.customQuery({
          query: mutateAddProductsToCartGql,
          queryVariables: virtualCartInput,
        });

        Logger.debug('[Result VirtualProduct]:', { data });

        if (data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(data.addProductsToCart.user_errors[0].message));
        }

        // eslint-disable-next-line consistent-return
        return data.addProductsToCart.cart as unknown as Cart;

      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  },
};
