export function addScrollbarWidthPadding() {
  const mobileBreakpoint = 1024;
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  if (scrollbarWidth && window.innerWidth >= mobileBreakpoint) {
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }
}

export function removeScrollbarWidthPadding() {
  document.body.style.paddingRight = '';
}
