export default `mutation generateCustomerTokenWithBankID(
	$order_ref: String!
	$time: Int
) {
	generateCustomerTokenWithBankID(
		order_ref: $order_ref
		time: $time
	) {
		message
		status
		token
		qr_auth_code
	}
}
`;
