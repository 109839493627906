import { computed, reactive, watch } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';
import { addScrollbarWidthPadding, removeScrollbarWidthPadding } from '../utils/addScrollbarWidthPadding';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isSearchVisible: false,
  isBankIdQrCodeModalOpen: false,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen);
  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const isMobileMenuOpen = computed(() => state.isMobileMenuOpen);
  const isQankIdQrCodeModalOpen = computed(() => state.isBankIdQrCodeModalOpen);

  watch(
    [isCartSidebarOpen, isWishlistSidebarOpen, isQankIdQrCodeModalOpen],
    () => {
      if (typeof window === 'undefined') return;
      if (isCartSidebarOpen.value || isWishlistSidebarOpen.value || isMobileMenuOpen.value || isQankIdQrCodeModalOpen.value) {
        addScrollbarWidthPadding();
        document.documentElement.classList.add('no-scroll');
      } else {
        document.documentElement.classList.remove('no-scroll');
        removeScrollbarWidthPadding();
      }
    },
    { immediate: true },
  );

  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
    state.isCartSidebarOpen = false;
    state.isWishlistSidebarOpen = false;
    if (!state.isMobileMenuOpen) document.documentElement.classList.remove('no-scroll');
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const toggleBankIdQrCodeModal = () => {
    state.isBankIdQrCodeModalOpen = !state.isBankIdQrCodeModalOpen;
  };

  const toggleSearchBar = () => {
    if (document) {
      if (state.isSearchVisible) {
        document.documentElement.classList.remove('no-scroll');
      } else {
        document.documentElement.classList.add('no-scroll');
      }
    }
    state.isSearchVisible = !state.isSearchVisible;
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isSearchVisible: computed(() => state.isSearchVisible),
    isBankIdQrCodeModalOpen: computed(() => state.isBankIdQrCodeModalOpen),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleSearchBar,
    toggleBankIdQrCodeModal,
  };
}

export default useUiState;
export * from './useUiState';
