import type { Ref } from '@nuxtjs/composition-api';
import { ref, useContext } from '@nuxtjs/composition-api';
import { CustomerAddressInput, Maybe, useCountrySearch } from '~/composables';
import type { ComposableFunctionArgs } from '~/composables/types';
import { Logger } from '~/helpers/logger';
import { transformUserCreateAddressInput, transformUserUpdateAddressInput } from '~/modules/customer/helpers/userAddressManipulator';
import { CustomHeaders, CustomQuery } from '~/types/core';
import addressGetter from '../../getters/addressGetter';
import useUser from '../useUser';
import type { UseAddressesErrors, UseAddressesInterface, UseAddressesParamsInput } from './useAddresses';

/**
 * @public
 *
 * Allows loading and manipulating addresses of the current user. These
 * addresses can be used for both billing and shipping.
 *
 * See the {@link UseAddressesInterface} for a list of methods and values available in this composable.
 */
export function useAddresses(): UseAddressesInterface {
  const error: Ref<UseAddressesErrors> = ref({
    load: null,
    save: null,
    remove: null,
    update: null,
  });
  const loading: Ref<boolean> = ref(false);
  const { app } = useContext();
  const { search: searchCountry } = useCountrySearch();
  const { isAuthenticated } = useUser();
  const context = app.$vsf;

  const load = async (customQuery?: CustomQuery, customHeaders?: CustomHeaders): Promise<Maybe<CustomerAddressInput[]>> => {
    Logger.debug('[Magento] load user addresses');
    if (!isAuthenticated.value) return [];
    let results = null;

    try {
      loading.value = true;
      const { data } = await context.$magento.api.getCustomerAddresses(customQuery, customHeaders);
      results = data?.customer?.addresses ?? [];
      Logger.debug('[Magento] load user addresses results:', results);
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('Magento] load user addresses error', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const save = async (params: ComposableFunctionArgs<UseAddressesParamsInput>) => {
    Logger.debug('[Magento] save user address:', params.address);
    let results = null;

    try {
      loading.value = true;
      const { data } = await context.$magento.api.createCustomerAddress(
        transformUserCreateAddressInput(params),
        params?.customQuery ?? null,
        params?.customHeaders,
      );
      results = data?.createCustomerAddress ?? {};
      Logger.debug('[Magento] save user address results:', params.address);
      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      Logger.error('[Magento] save user address error:', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const update = async (params: ComposableFunctionArgs<UseAddressesParamsInput>) => {
    Logger.debug('[Magento] update user address:', params.address);
    let results = null;

    try {
      loading.value = true;
      const { data } = await context.$magento.api.updateCustomerAddress(
        transformUserUpdateAddressInput(params),
        params?.customQuery ?? null,
        params?.customHeaders,
      );
      results = data?.updateCustomerAddress ?? {};
      Logger.debug('[Magento] update user address results:', results);
      error.value.update = null;
    } catch (err) {
      error.value.update = err;
      Logger.error('[Magento] update user address error:', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const remove = async (params: ComposableFunctionArgs<UseAddressesParamsInput>) => {
    Logger.debug('[Magento] remove user address:', params.address);
    let results = null;

    try {
      loading.value = true;
      const { data } = await context.$magento.api.deleteCustomerAddress(params.address.id, params?.customQuery ?? null, params?.customHeaders);
      results = !!data.deleteCustomerAddress;
      Logger.debug('[Magento] remove user address results:', results);
      error.value.remove = null;
    } catch (err) {
      error.value.remove = err;
      Logger.error('[Magento] remove user address error:', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const isSameAddress = (address1: CustomerAddressInput, address2: CustomerAddressInput) => {
    if (!address1 || !address2) return false;
    if (address1.firstname !== address2.firstname) return false;
    if (address1.lastname !== address2.lastname) return false;
    if (address1.city !== address2.city) return false;
    if (address1.country_code !== address2.country_code) return false;
    if (address1.postcode !== address2.postcode) return false;
    if (address1.telephone !== address2.telephone) return false;
    if (address1.region?.region_code !== address2.region?.region_code) return false;
    if (address1.street?.[0] !== address2.street?.[0]) return false;
    return true;
  };

  const saveAfterCheckout = async (params: ComposableFunctionArgs<UseAddressesParamsInput>) => {
    if (!isAuthenticated.value) return false;
    const addresses = await load();
    const formatedNewAddress = transformUserCreateAddressInput(params);

    // eslint-disable-next-line no-restricted-syntax
    for (const address of addresses) {
      if (
        isSameAddress(
          { ...address, street: [address.street?.join(' ')] },
          { ...formatedNewAddress, street: formatedNewAddress.street[0] as unknown as string[] },
        )
      ) {
        return false;
      }
    }

    const country = await searchCountry({ id: formatedNewAddress.country_code });
    const regionInformation = addressGetter.regionList(country);
    const regionId = regionInformation.find((r) => r.abbreviation === formatedNewAddress.region.region_code)?.id;

    await save({
      address: {
        ...formatedNewAddress,
        region: { ...formatedNewAddress.region, region_id: regionId },
        street: formatedNewAddress.street?.[0][0] as unknown as string[],
        default_billing: true,
      },
    });
    return true;
  };

  return {
    error,
    loading,
    load,
    save,
    update,
    remove,
    saveAfterCheckout,
  };
}

export * from './useAddresses';
export default useAddresses;
