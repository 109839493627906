export default `
query getProductsByType($search: String!) {
	products_by_type(search: $search) {
		total_count
		analys {
			name
			url_key
			analysis_type
			url_rewrites {
				url
			}
			thumbnail {
				url
				position
				disabled
				label
			}
			hover_image {
				url
				position
				disabled
				label
			}
			price_range {
				maximum_price {
					final_price {
						currency
						value
					}
					regular_price {
						currency
						value
					}
				}
				minimum_price {
					final_price {
						currency
						value
					}
					regular_price {
						currency
						value
					}
				}
			}
		}
		analyspaket {
			name
			url_key
			analysis_type
			url_rewrites {
				url
			}
			hover_image {
				url
				position
				disabled
				label
			}
			thumbnail {
				url
				position
				disabled
				label
			}
			price_range {
				maximum_price {
					final_price {
						currency
						value
					}
					regular_price {
						currency
						value
					}
				}
				minimum_price {
					final_price {
						currency
						value
					}
					regular_price {
						currency
						value
					}
				}
			}
		}
	}
}
`;
