export default `mutation removeItemFromCart($input: RemoveItemFromCartInput) {
  removeItemFromCart(input: $input) {
    cart {
      id
      email
      is_virtual
      applied_coupons {
        code
      }
      prices {
        subtotal_excluding_tax {
          value
        },
        subtotal_including_tax {
          value
        },
        applied_taxes {
          amount {
            value
          },
          label
        }
        discounts {
          amount {
            value
          },
          label
        }
        grand_total {
          value
        }
      }
      items {
        uid
        product {
          uid
          __typename
          sku
          name
          stock_status
          analysis_type
          only_x_left_in_stock
          rating_summary
          short_description {
            html
          }
          thumbnail {
            url
            position
            disabled
            label
          }
          url_key
          url_rewrites {
            url
          }
          price_range {
            maximum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            minimum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
        }
        prices {
          row_total {
            value
          }
          row_total_including_tax {
            value
          }
          total_item_discount {
            value
          }
        }
        quantity
      }
      total_quantity
      shipping_addresses {
        firstname
        lastname
        street
        city
        company
        region {
          code
          region_id
          label
        }
        postcode
        telephone
        country {
          code
          label
        }

      }
      billing_address {
        firstname
        lastname
        street
        city
        company
        region {
          code
          region_id
          label
        }
        postcode
        telephone
        country {
          code
          label
        }
      }
    }
  }
}`;
