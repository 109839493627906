import { onMounted, onUnmounted } from '@nuxtjs/composition-api';

export function useAlertBarOffset(): void {
  onMounted(() => {
    const alertBar = document.querySelector('.alert-bar');
    const alertBarBoundingClientRect = alertBar?.getBoundingClientRect();
    const alertBarOffset = Math.min(-(alertBarBoundingClientRect?.top || 0), alertBarBoundingClientRect?.height || 0);

    document.documentElement.style.setProperty('--alert-bar-height', `${(alertBarBoundingClientRect?.height || 0) / 16}rem`);
    document.documentElement.style.setProperty('--alert-bar-offset', `${alertBarOffset / 16}rem`);
  });

  onUnmounted(() => {
    const alertBar = document.querySelector('.alert-bar');
    if (!alertBar) {
      document.documentElement.style.setProperty('--alert-bar-height', '0');
      return;
    }
    document.documentElement.style.setProperty('--alert-bar-height', '2.5rem');
  });
}
