/* eslint-disable no-await-in-loop */

import { computed, reactive } from '@nuxtjs/composition-api';
import { getDeviceType, getStartBankIdUrl } from '~/helpers/bankId';
import { CollectBankIdInput, StartBankIdInput } from '~/modules/GraphQL/custom-types';
import cancelBankIdProcessGql from '~/modules/customer/queries/cancelBankIdProcess.gql';
import { useUiState } from '../../../../composables';
import useApi from '../../../../composables/useApi';
import confirmLoginProcessGql from '../../queries/confirmLoginProcess.gql';
import confirmRegisterProcessGql from '../../queries/confirmRegisterProcess.gql';
import startBankIdProcessGql from '../../queries/startBankIdProcess.gql';
import { BankIDResponse, CreateCustomerWithBankIDQuery, GenerateCustomerTokenWithBankIDQuery, StartBankIDQuery } from '../types';
import { BankIdInterface } from './useBankId';

const state = reactive<BankIdInterface>({
  bankIdQrCode: '',
  orderReference: '',
});

/**
 * @public
 *
 * Custom composable for handling bank id authentication.
 */
export function useBankId() {
  const { query } = useApi();
  const { toggleBankIdQrCodeModal, isBankIdQrCodeModalOpen } = useUiState();

  const removeOrderReference = () => {
    state.orderReference = '';
  };

  const cancelBankIdProcess = async () => {
    if (!state.orderReference) return;
    if (isBankIdQrCodeModalOpen.value) toggleBankIdQrCodeModal();
    state.bankIdQrCode = '';
    await query<BankIDResponse>(cancelBankIdProcessGql, { order_ref: state.orderReference });
    removeOrderReference();
  };

  const setBankIdQrCode = (qrCode: string) => {
    state.bankIdQrCode = qrCode;
  };

  const collectBankIdResult = async ({ socialSecurityNumber, orderRef, isLogin, ...rest }: CollectBankIdInput) => {
    const variables = isLogin
      ? { order_ref: orderRef }
      : {
          input: {
            order_ref: orderRef,
            social_security_number: socialSecurityNumber.toString(),
            ...rest,
          },
        };

    let status = '';
    let elapsedTime = 0;

    while (status !== 'success' && status !== 'failed' && elapsedTime < 60) {
      const { data, errors } = isLogin
        ? await query<GenerateCustomerTokenWithBankIDQuery>(confirmLoginProcessGql, variables)
        : await query<CreateCustomerWithBankIDQuery>(confirmRegisterProcessGql, variables);

      if (errors) {
        throw new Error(errors[0].message);
      }

      status = isLogin
        ? (data as GenerateCustomerTokenWithBankIDQuery).generateCustomerTokenWithBankID?.status
        : (data as CreateCustomerWithBankIDQuery).createCustomerWithBankID?.status;

      if (status === 'success') {
        // eslint-disable-next-line no-promise-executor-return
        return isLogin ? (data as GenerateCustomerTokenWithBankIDQuery).generateCustomerTokenWithBankID.token : 'success';
      }
      if (status === 'failed') {
        throw new Error('BankId failed');
      }

      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 2000));
      elapsedTime += 2;
    }
    throw new Error('BankId timeout after 60 seconds');
  };

  const collectExternalDeviceBankIdResult = async ({ socialSecurityNumber, orderRef, isLogin, ...rest }: CollectBankIdInput) => {
    toggleBankIdQrCodeModal();
    const variables = isLogin
      ? { order_ref: orderRef }
      : {
          input: {
            order_ref: orderRef,
            social_security_number: socialSecurityNumber.toString(),
            ...rest,
          },
        };

    let status = '';
    let time = 1;

    while (status !== 'success' && status !== 'failed' && time <= 30) {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const { data, errors } = isLogin
        ? await query<GenerateCustomerTokenWithBankIDQuery>(confirmLoginProcessGql, { ...variables, time })
        : await query<CreateCustomerWithBankIDQuery>(confirmRegisterProcessGql, { ...variables, time });

      if (errors) {
        throw new Error(errors[0].message);
      }

      status = isLogin
        ? (data as GenerateCustomerTokenWithBankIDQuery).generateCustomerTokenWithBankID?.status
        : (data as CreateCustomerWithBankIDQuery).createCustomerWithBankID?.status;

      if (status === 'success') {
        toggleBankIdQrCodeModal();
        return isLogin ? (data as GenerateCustomerTokenWithBankIDQuery).generateCustomerTokenWithBankID.token : 'success';
      }
      if (status === 'failed') {
        throw new Error('BankId failed');
      }

      const qrCode: string = isLogin
        ? (data as GenerateCustomerTokenWithBankIDQuery).generateCustomerTokenWithBankID?.qr_auth_code
        : (data as CreateCustomerWithBankIDQuery).createCustomerWithBankID?.qr_auth_code;
      setBankIdQrCode(qrCode);
      time++;
    }
    throw new Error('BankId timeout after 60 seconds');
  };

  const startBankId = async ({ socialSecurityNumber, isOnAnotherDevice, ...rest }: StartBankIdInput) => {
    const device = getDeviceType();
    const variables = {
      social_security_number: socialSecurityNumber,
      bankid_type: isOnAnotherDevice ? 'ANOTHER_DEVICE' : 'SAME_DEVICE',
      device,
    };
    const { data, errors } = await query<StartBankIDQuery>(startBankIdProcessGql, variables);
    if (errors) {
      throw new Error(errors[0].message);
    }
    state.orderReference = data?.startBankIdProcess?.order_ref as string;
    if (isOnAnotherDevice) {
      setBankIdQrCode(data.startBankIdProcess.qr_auth_code as string);
      const token = await collectExternalDeviceBankIdResult({
        socialSecurityNumber,
        ...rest,
        orderRef: state.orderReference,
      });
      return token;
    }
    const launchAppLink = getStartBankIdUrl(data?.startBankIdProcess?.auto_start_token as string);
    const startBankIdAnchor = document.querySelector('#start-bankid-anchor') as HTMLAnchorElement;
    startBankIdAnchor.href = launchAppLink;
    startBankIdAnchor.click();
    const token = await collectBankIdResult({ socialSecurityNumber, ...rest, orderRef: state.orderReference });
    return token;
  };

  return {
    bankIdQrCode: computed(() => state.bankIdQrCode),
    removeOrderReference,
    cancelBankIdProcess,
    setBankIdQrCode,
    startBankId,
  };
}

export default useBankId;
export * from './useBankId';
