import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { Cart, RemoveCouponFromCartMutation } from '~/modules/GraphQL/types';
import removeCouponFromCartGql from '~/modules/checkout/queries/removeCouponFromCart.gql';

export const removeCouponCommand = {
  execute: async (context: VsfContext, { currentCart }) => {
    Logger.debug('[Magento]: Remove coupon from cart', { currentCart });

    const { data, errors } = await context.$magento.api.customQuery({
      query: removeCouponFromCartGql,
      queryVariables: { input: { cart_id: currentCart.id } },
    });

    Logger.debug('[Result]:', { data });

    return {
      updatedCart: (data as RemoveCouponFromCartMutation).removeCouponFromCart?.cart as unknown as Cart,
      updatedCoupon: { code: '' },
      errors,
    };
  },
};
