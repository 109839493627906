

















import { defineComponent, useRoute } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import AppHeader from '~/components/AppHeader.vue';
import CookieBanner from '~/components/CookieBanner.vue';
import AlertBar from '~/components/General/AlertBar.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LazyHydrate,
    AppHeader,
    IconSprite,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    AlertBar,
    CookieBanner,
  },

  setup() {
    const route = useRoute();
    const { isCartSidebarOpen, isWishlistSidebarOpen } = useUiState();

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      route,
    };
  },
  head: {
    link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
    title: 'Blodkollen',
  },
});
