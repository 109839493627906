import mutateSetPaymentMethodOnCartGql from '~/modules/checkout/queries/mutateSetPaymentMethodOnCart.gql';
import { CustomSetPaymentMethodOnCartMutation } from '~/modules/checkout/types';
import { UseContextReturn } from '~/types/core';
import type { PaymentMethodParams } from '../usePaymentProvider';

export const setPaymentMethodOnCartCommand = {
  execute: async (context: UseContextReturn, params: PaymentMethodParams): Promise<string> => {
    const { data }: { data: CustomSetPaymentMethodOnCartMutation } = await context.app.$vsf.$magento.api.customQuery({
      query: mutateSetPaymentMethodOnCartGql,
      queryVariables: { input: params },
    });

    return data?.setPaymentMethodOnCart?.cart.svea_iframe_snippet;
  },
};
