import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { Cart, RemoveItemFromCartInput } from '~/modules/GraphQL/types';
import mutateRemoveItemFromCartGql from '~/modules/checkout/queries/mutateRemoveItemFromCart.gql';
import { CustomQuery } from '~/types/core';

export const removeItemCommand = {
  execute: async (context: VsfContext, { currentCart, product, customQuery }) => {
    Logger.debug('[Magento]: Remove item from cart', {
      product,
      currentCart,
    });

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);

    if (!item) {
      return;
    }

    const removeItemParams: { input: RemoveItemFromCartInput } = {
      input: {
        cart_id: currentCart.id,
        cart_item_uid: item.uid,
      },
    };

    const { data }: any = await context.$magento.api.customQuery(
      { query: mutateRemoveItemFromCartGql, queryVariables: removeItemParams },
      customQuery as CustomQuery,
    );

    Logger.debug('[Result]:', { data });

    // eslint-disable-next-line consistent-return
    return data.removeItemFromCart.cart as unknown as Cart;
  },
};
