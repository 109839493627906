


































import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfScrollable } from '@storefront-ui/vue';
import { useAlertBarOffset } from '~/composables';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { SearchResultsByType } from '~/modules/catalog/types';
import SearchResultsItem from './SearchResultsItem.vue';

export default defineComponent({
  name: 'SearchResults',
  components: {
    SfScrollable,
    SearchResultsItem,
  },
  props: {
    searchResults: {
      type: Object as PropType<SearchResultsByType | null>,
    },
  },
  setup(props) {
    useAlertBarOffset();

    const hasSearchResults = computed(() => props.searchResults?.analys.length > 0 || props.searchResults?.analyspaket.length > 0);

    return {
      hasSearchResults,
      productGetters,
    };
  },
});
