export default `
query getStaticText(
	$label : String = "Label 1"
) {
	static_text(
		label: $label
	) {
		label
		text {
			html
		}
		}
}
`;
