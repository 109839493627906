import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67d3f446 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _bb22b05a = () => interopDefault(import('../modules/catalog/pages/AllAnalysesList.vue' /* webpackChunkName: "" */))
const _d632724c = () => interopDefault(import('../modules/catalog/pages/AnalysesList.vue' /* webpackChunkName: "" */))
const _77f32178 = () => interopDefault(import('../modules/catalog/pages/analysis.vue' /* webpackChunkName: "" */))
const _7adc6aa9 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _a5b44d62 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _76cc14b4 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _7e9c5c36 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _35589928 = () => interopDefault(import('../modules/checkout/pages/Checkout/PersonalInformation.vue' /* webpackChunkName: "" */))
const _032b2cbb = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _86fc5c9c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _340b8320 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _762a70c8 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _f8a80fe0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _1069cd40 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _7e1c9fec = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _557c6e6e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _31aebd93 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _0585d669 = () => interopDefault(import('../pages/CustomerService.vue' /* webpackChunkName: "pages/CustomerService" */))
const _acf31d46 = () => interopDefault(import('../modules/customer/pages/MyAccount/ForgotPassword.vue' /* webpackChunkName: "" */))
const _dedc328c = () => interopDefault(import('../modules/customer/pages/MyAccount/Login.vue' /* webpackChunkName: "" */))
const _a8990ede = () => interopDefault(import('../modules/customer/pages/Reports/TestResultsHistory.vue' /* webpackChunkName: "" */))
const _1c4b1b8d = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _7b1e2729 = () => interopDefault(import('../modules/catalog/pages/Packages.vue' /* webpackChunkName: "" */))
const _74cf654b = () => interopDefault(import('../modules/clinics/pages/SamplingSites.vue' /* webpackChunkName: "" */))
const _7aa6c36e = () => interopDefault(import('../modules/customer/pages/Reports/Reports.vue' /* webpackChunkName: "" */))
const _1fe86a9a = () => interopDefault(import('../modules/customer/pages/Reports/TestResults.vue' /* webpackChunkName: "" */))
const _63f644d2 = () => interopDefault(import('../modules/customer/pages/MyAccount/Signup.vue' /* webpackChunkName: "" */))
const _02d172db = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _6afa768a = () => interopDefault(import('../modules/customer/pages/MyAccount/LoginAsCustomer.vue' /* webpackChunkName: "" */))
const _3d57f95a = () => interopDefault(import('../modules/catalog/pages/package.vue' /* webpackChunkName: "" */))
const _40ff48bc = () => interopDefault(import('../modules/clinics/pages/LabPage.vue' /* webpackChunkName: "" */))
const _b6a58e88 = () => interopDefault(import('../modules/catalog/pages/Partner.vue' /* webpackChunkName: "" */))
const _000d86a9 = () => interopDefault(import('../modules/customer/pages/Reports/AnalysisResults.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _67d3f446,
    name: "home___en"
  }, {
    path: "/sv",
    component: _67d3f446,
    name: "home___sv"
  }, {
    path: "/en/alla-analyser",
    component: _bb22b05a,
    meta: {"titleLabel":"Analyses List"},
    name: "analysis list___en"
  }, {
    path: "/en/analys",
    component: _d632724c,
    meta: {"titleLabel":"Analyses List"},
    name: "analyses___en",
    children: [{
      path: ":slug",
      component: _77f32178,
      meta: {"titleLabel":"Analysis"},
      name: "simple analysis___en"
    }]
  }, {
    path: "/en/cart",
    component: _7adc6aa9,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _a5b44d62,
    name: "checkout___en",
    children: [{
      path: "payment",
      component: _76cc14b4,
      name: "payment___en"
    }, {
      path: "thank-you",
      component: _7e9c5c36,
      name: "thank-you___en"
    }, {
      path: "your-info",
      component: _35589928,
      name: "your-info___en"
    }]
  }, {
    path: "/en/Cms",
    component: _032b2cbb,
    name: "Cms___en"
  }, {
    path: "/en/customer",
    component: _86fc5c9c,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _340b8320,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-profile",
      component: _762a70c8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-wishlist",
      component: _f8a80fe0,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _1069cd40,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _7e1c9fec,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _557c6e6e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _31aebd93,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/CustomerService",
    component: _0585d669,
    name: "CustomerService___en"
  }, {
    path: "/en/forgot-password",
    component: _acf31d46,
    meta: {"titleLabel":"Forgot Password"},
    name: "forgot password___en"
  }, {
    path: "/en/Home",
    component: _67d3f446,
    name: "Home___en"
  }, {
    path: "/en/kundtjanst",
    component: _0585d669,
    name: "customer service___en"
  }, {
    path: "/en/login",
    component: _dedc328c,
    meta: {"titleLabel":"Log In"},
    name: "login___en"
  }, {
    path: "/en/mina-testresultat",
    component: _a8990ede,
    meta: {"titleLabel":"Test Results History"},
    name: "test results history___en"
  }, {
    path: "/en/Page",
    component: _1c4b1b8d,
    name: "Page___en"
  }, {
    path: "/en/provanalyser",
    component: _7b1e2729,
    meta: {"titleLabel":"Packages List"},
    name: "package list___en"
  }, {
    path: "/en/provtagningsstallen",
    component: _74cf654b,
    name: "sampling-sites___en"
  }, {
    path: "/en/reports",
    component: _7aa6c36e,
    meta: {"titleLabel":"Reports"},
    name: "reports___en",
    children: [{
      path: "/en/reports/:reportOrderId",
      component: _1fe86a9a,
      meta: {"titleLabel":"Test Results"},
      name: "test results___en"
    }]
  }, {
    path: "/en/signup",
    component: _63f644d2,
    meta: {"titleLabel":"Sign Up"},
    name: "signup___en"
  }, {
    path: "/sv/alla-analyser",
    component: _bb22b05a,
    meta: {"titleLabel":"Analyses List"},
    name: "analysis list___sv"
  }, {
    path: "/sv/analys",
    component: _d632724c,
    meta: {"titleLabel":"Analyses List"},
    name: "analyses___sv",
    children: [{
      path: ":slug",
      component: _77f32178,
      meta: {"titleLabel":"Analysis"},
      name: "simple analysis___sv"
    }]
  }, {
    path: "/sv/cart",
    component: _7adc6aa9,
    name: "cart___sv"
  }, {
    path: "/sv/checkout",
    component: _a5b44d62,
    name: "checkout___sv",
    children: [{
      path: "payment",
      component: _76cc14b4,
      name: "payment___sv"
    }, {
      path: "thank-you",
      component: _7e9c5c36,
      name: "thank-you___sv"
    }, {
      path: "your-info",
      component: _35589928,
      name: "your-info___sv"
    }]
  }, {
    path: "/sv/Cms",
    component: _032b2cbb,
    name: "Cms___sv"
  }, {
    path: "/sv/customer",
    component: _86fc5c9c,
    meta: {"titleLabel":"My Account"},
    name: "customer___sv",
    children: [{
      path: "addresses-details",
      component: _340b8320,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___sv"
    }, {
      path: "my-profile",
      component: _762a70c8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___sv"
    }, {
      path: "my-wishlist",
      component: _f8a80fe0,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___sv"
    }, {
      path: "order-history",
      component: _1069cd40,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___sv"
    }, {
      path: "addresses-details/create",
      component: _7e1c9fec,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___sv"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _557c6e6e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___sv"
    }, {
      path: "order-history/:orderId",
      component: _31aebd93,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___sv"
    }]
  }, {
    path: "/sv/CustomerService",
    component: _0585d669,
    name: "CustomerService___sv"
  }, {
    path: "/sv/forgot-password",
    component: _acf31d46,
    meta: {"titleLabel":"Forgot Password"},
    name: "forgot password___sv"
  }, {
    path: "/sv/Home",
    component: _67d3f446,
    name: "Home___sv"
  }, {
    path: "/sv/kundtjanst",
    component: _0585d669,
    name: "customer service___sv"
  }, {
    path: "/sv/login",
    component: _dedc328c,
    meta: {"titleLabel":"Log In"},
    name: "login___sv"
  }, {
    path: "/sv/mina-testresultat",
    component: _a8990ede,
    meta: {"titleLabel":"Test Results History"},
    name: "test results history___sv"
  }, {
    path: "/sv/Page",
    component: _1c4b1b8d,
    name: "Page___sv"
  }, {
    path: "/sv/provanalyser",
    component: _7b1e2729,
    meta: {"titleLabel":"Packages List"},
    name: "package list___sv"
  }, {
    path: "/sv/provtagningsstallen",
    component: _74cf654b,
    name: "sampling-sites___sv"
  }, {
    path: "/sv/reports",
    component: _7aa6c36e,
    meta: {"titleLabel":"Reports"},
    name: "reports___sv",
    children: [{
      path: "/sv/reports/:reportOrderId",
      component: _1fe86a9a,
      meta: {"titleLabel":"Test Results"},
      name: "test results___sv"
    }]
  }, {
    path: "/sv/signup",
    component: _63f644d2,
    meta: {"titleLabel":"Sign Up"},
    name: "signup___sv"
  }, {
    path: "/en/customer/account/createPassword",
    component: _02d172db,
    name: "reset-password___en"
  }, {
    path: "/en/loginascustomer/login/index",
    component: _6afa768a,
    name: "login-as-customer___en"
  }, {
    path: "/sv/customer/account/createPassword",
    component: _02d172db,
    name: "reset-password___sv"
  }, {
    path: "/sv/loginascustomer/login/index",
    component: _6afa768a,
    name: "login-as-customer___sv"
  }, {
    path: "/en/analyspaket/:slug",
    component: _3d57f95a,
    meta: {"titleLabel":"Package"},
    name: "package___en"
  }, {
    path: "/en/mottagning/:slug",
    component: _40ff48bc,
    name: "laboratory___en"
  }, {
    path: "/en/partner/:slug",
    component: _b6a58e88,
    meta: {"titleLabel":"Partner"},
    name: "partner___en"
  }, {
    path: "/sv/analyspaket/:slug",
    component: _3d57f95a,
    meta: {"titleLabel":"Package"},
    name: "package___sv"
  }, {
    path: "/sv/mottagning/:slug",
    component: _40ff48bc,
    name: "laboratory___sv"
  }, {
    path: "/sv/partner/:slug",
    component: _b6a58e88,
    meta: {"titleLabel":"Partner"},
    name: "partner___sv"
  }, {
    path: "/en/reports/:reportOrderId/:analysisResultId",
    component: _000d86a9,
    meta: {"titleLabel":"Analysis Results"},
    name: "analysis results___en"
  }, {
    path: "/sv/reports/:reportOrderId/:analysisResultId",
    component: _000d86a9,
    meta: {"titleLabel":"Analysis Results"},
    name: "analysis results___sv"
  }, {
    path: "/en/:slug",
    component: _1c4b1b8d,
    name: "page___en"
  }, {
    path: "/sv/:slug",
    component: _1c4b1b8d,
    name: "page___sv"
  }, {
    path: "/en/:parentSlug/analys",
    component: _d632724c,
    meta: {"titleLabel":"Package Analyses List"},
    name: "package analyses___en",
    children: [{
      path: ":slug",
      component: _77f32178,
      meta: {"titleLabel":"Package Analysis"},
      name: "simple package analysis___en"
    }]
  }, {
    path: "/sv/:parentSlug/analys",
    component: _d632724c,
    meta: {"titleLabel":"Package Analyses List"},
    name: "package analyses___sv",
    children: [{
      path: ":slug",
      component: _77f32178,
      meta: {"titleLabel":"Package Analysis"},
      name: "simple package analysis___sv"
    }]
  }, {
    path: "/en/:parentSlug/:slug",
    component: _1c4b1b8d,
    name: "subpage___en"
  }, {
    path: "/sv/:parentSlug/:slug",
    component: _1c4b1b8d,
    name: "subpage___sv"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
