import { Maybe } from '~/composables';

const formatSwedishKrona = (num?: Maybe<number>) => {
  if (typeof num !== 'number') return 'kr';
  const formatedNum = Number.isInteger(num) ? num : num.toFixed(2);
  return `${formatedNum}kr`;
};

const formatCurrency = (value: number | string, locale: string, options: Intl.NumberFormatOptions): string => {
  if (typeof value === 'string') {
    // eslint-disable-next-line no-param-reassign
    value = Number(value);
  }
  // eslint-disable-next-line no-param-reassign
  if (locale === 'se' || locale === 'en') {
    return formatSwedishKrona(value).replace('.', ',');
  }
  return new Intl.NumberFormat(locale, { style: 'currency', ...options }).format(value);
};

export default formatCurrency;
