export default `
query productDetails(
	$search: String = ""
	$filter: ProductAttributeFilterInput
	$pageSize: Int = 10
	$currentPage: Int = 1
	$sort: ProductAttributeSortInput
) {
	products(
		search: $search
		filter: $filter
		sort: $sort
		pageSize: $pageSize
		currentPage: $currentPage
	) {
		items {
			uid
			sku
			name
			analysis_type
			stock_status
			only_x_left_in_stock
			available_in_all_clinics
			laboratory_id
			thumbnail {
				url
				position
				disabled
				label
			}
			hover_image {
				url
				position
				disabled
				label
			}
			url_key
			url_rewrites {
				url
			}
			categories {
				uid
				name
				url_suffix
				url_path
				breadcrumbs {
					category_name
					category_url_path
				}
			}
			rating_summary
			small_image {
				url
				position
				disabled
				label
			}
			image {
				url
				position
				disabled
				label
			}
			media_gallery {
				url
				position
				disabled
				label
			}
			thumbnail {
				url
				position
				disabled
				label
			}
			url_key
			url_rewrites {
				url
			}
			meta_description
			meta_keyword
			meta_title
			package_benefits {
				html
			}
			health_parameters_evaluated {
				html
			}
			additional_info {
				html
			}
			short_description {
				html
			}
			
			options_container
			special_to_date

			gift_message_available
		}
	}
}
`;
