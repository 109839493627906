











import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { SfButton, SfLink } from '@storefront-ui/vue';
import Cookies from 'js-cookie';

export default defineComponent({
  name: 'CookieBanner',
  components: {
    SfButton,
    SfLink,
  },
  setup() {
    const isAccepted = ref(true);
    const onAcceptCookies = () => {
      Cookies.set('cookie_banner_confirmed', 'true', { expires: 365, path: '/' });
      isAccepted.value = true;
    };

    onMounted(() => {
      const cookie = Cookies.get('cookie_banner_confirmed');
      if (!cookie) {
        isAccepted.value = false;
      }
    });
    return {
      onAcceptCookies,
      isAccepted,
    };
  },
});
