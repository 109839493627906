export default `query categories(
	$pageSize: Int = 1
	$currentPage: Int = 1
	$filters: CategoryFilterInput
) {
	categories(
		pageSize: $pageSize
		currentPage: $currentPage
		filters: $filters
	) {
		items {
			children {
				uid
				name
				include_in_menu
				products {
					items {
						name
						url_key
						analysis_type
					}
				}
			}
		}
	}
}
`;
