import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { throttle } from 'lodash-es';
import { UseUiHeader } from './useUiHeader';

export function useUiHeader(): UseUiHeader {
  const isScrollingDown = ref(false);
  let resizeTimeout: ReturnType<typeof setTimeout> | null = null;

  let scrollPosition = 0;
  let lastChangeScrollPosition = 0; // variable to track the last scroll position when the header visibility changed
  const scrollChangeThreshold = 10; // change this value to the scroll difference you want

  const updateScrollingUp = throttle(() => {
    const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
    const headerHeight = document.querySelector('.sf-header')?.clientHeight ?? 0;
    const scrollDifference = Math.abs(lastChangeScrollPosition - currentScrollPosition);

    if (scrollDifference > scrollChangeThreshold) {
      isScrollingDown.value = scrollPosition < currentScrollPosition && currentScrollPosition > headerHeight;
      lastChangeScrollPosition = currentScrollPosition;
    }

    scrollPosition = currentScrollPosition;
  }, 250);

  // This is needed because we don't want to hide the header when the user is resizing the window
  const toggleScrollListener = () => {
    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }

    // Unbind the scroll event to prevent the function from triggering
    window.removeEventListener('scroll', updateScrollingUp);

    // Set a timeout to re-bind the scroll event after resizing has finished
    resizeTimeout = setTimeout(() => {
      window.addEventListener('scroll', updateScrollingUp);
    }, 250); // Adjust the timeout duration as needed
  };

  onMounted(() => {
    toggleScrollListener();
    window.addEventListener('resize', toggleScrollListener);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', toggleScrollListener);
  });

  return {
    isScrollingDown,
  };
}
