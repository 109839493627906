const middleware = {}

middleware['analyses'] = require('../middleware/analyses.ts')
middleware['analyses'] = middleware['analyses'].default || middleware['analyses']

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['is-not-authenticated'] = require('../middleware/is-not-authenticated.ts')
middleware['is-not-authenticated'] = middleware['is-not-authenticated'].default || middleware['is-not-authenticated']

middleware['package'] = require('../middleware/package.ts')
middleware['package'] = middleware['package'].default || middleware['package']

middleware['url-resolver'] = require('../middleware/url-resolver.ts')
middleware['url-resolver'] = middleware['url-resolver'].default || middleware['url-resolver']

export default middleware
