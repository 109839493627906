import { ref } from '@nuxtjs/composition-api';
import { UseHoverImageInterface } from './useHoverImage';

export function useHoverImage(): UseHoverImageInterface {
  const isHovered = ref(false);

  const hoverImage = () => {
    isHovered.value = true;
  };

  const unhoverImage = () => {
    isHovered.value = false;
  };

  return {
    isHovered,
    hoverImage,
    unhoverImage,
  };
}
