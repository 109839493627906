


















import { computed, defineComponent, ref, useFetch, useRoute } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import { useApi } from '~/composables';
import getAllPackagesByCategoryBasicGql from '~/modules/catalog/product/queries/getAllPackagesByCategoryBasic.gql';
import { AllAnalysisCategoriesProductsQuery, CustomCategoryTree } from '~/modules/catalog/types';
import HeaderNavigationPackages from './HeaderNavigationPackages.vue';

export default defineComponent({
  name: 'AllPackagesSection',
  components: {
    SvgImage,
    HeaderNavigationPackages,
  },
  setup() {
    const route = useRoute();
    const isActive = ref(false);
    const isAnalaysisPage = computed(() => route.value?.matched[0]?.name.split('___')[0] === 'analysis list');

    const isFetching = ref(true);
    const { query } = useApi();
    const packagesByCategory = ref<CustomCategoryTree[] | null>(null);

    const filters = {
      name: {
        match: 'Analyspaket',
      },
    };

    const fetchPackagesBaseData = async () => {
      const result = await query<AllAnalysisCategoriesProductsQuery>(getAllPackagesByCategoryBasicGql, { filters });
      packagesByCategory.value = result.data.categories.items[0].children.filter((category) => category.include_in_menu);
      isFetching.value = false;
    };

    useFetch(async () => {
      await fetchPackagesBaseData();
    });

    const toggleActive = (event: MouseEvent) => {
      const analysesNavigation = document.querySelector('#analyses-navigation');
      if (analysesNavigation && analysesNavigation.contains(event.target as Node)) {
        return;
      }
      isActive.value = !isActive.value;
    };

    const onClickOutside = () => {
      isActive.value = false;
    };

    return {
      isActive,
      toggleActive,
      packagesByCategory,
      isAnalaysisPage,
      onClickOutside,
    };
  },
});
