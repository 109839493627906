import { ProductDetailsQuery } from '@vue-storefront/magento-api';
import { defineStore } from 'pinia';
import { useApi } from '~/composables';
import { AllAnalysisCategoriesProductsQuery, CustomCategoryListQuery, CustomCategoryTree, CustomProduct, CustomProductInterface } from '../../types';
import getAllAnalaysisCategoriesProductsGql from '../queries/getAllAnalaysisCategoriesProducts.gql';
import getAnalysisCategoriesProductsGql from '../queries/getAnalysisCategoriesProducts.gql';
import getAnalysisDetailsGql from '../queries/getAnalysisDetails.gql';

export const useAnalysesStore = defineStore({
  id: 'analyses',

  state: () => ({
    analysesCount: 0,
    analysesCategories: [] as CustomCategoryTree[],
    openCategories: [] as string[],
    defaultAnalysis: null as CustomProductInterface | null,
    hasFetchedCategories: false,
  }),

  actions: {
    async fetchProducts(packageSku?: string) {
      const { query } = useApi();
      if (this.hasFetchedCategories) return;
      if (packageSku) {
        const variables = {
          filter: {
            sku: {
              eq: packageSku,
            },
          },
        };
        const { data } = await query<CustomCategoryListQuery>(getAnalysisCategoriesProductsGql, variables);
        this.analysesCategories = data.products?.items?.[0].analysis.children;
        this.defaultAnalysis = data.products?.items?.[0].analysis.default_analysis;
        this.analysesCount = data.products?.items?.[0].analysis.total_count;
        this.hasFetchedCategories = true;
        return;
      }
      const varialbes = {
        filters: {
          name: {
            match: 'Analys',
          },
        },
      };

      const defaultSku = 'A157';
      const [categories, defaultProduct] = await Promise.all([
        query<AllAnalysisCategoriesProductsQuery>(getAllAnalaysisCategoriesProductsGql, varialbes),
        query<ProductDetailsQuery>(getAnalysisDetailsGql, { filter: { sku: { eq: defaultSku } } }),
      ]);
      this.analysesCategories = categories.data?.categories.items[0].children;
      this.defaultAnalysis = defaultProduct?.data.products.items?.[0] as CustomProduct;
      this.hasFetchedCategories = true;
    },
    setOpenCategories(categoryTitles: string[]) {
      this.openCategories = [...categoryTitles];
    },
    reset() {
      this.analysesCount = 0;
      this.analysesCategories = [];
      this.openCategories = [];
      this.defaultAnalysis = null;
      this.hasFetchedCategories = false;
    },
  },
});
