export default `
mutation generateCustomerTokenWithAuthCode(
	$input: GenerateCustomerTokenWithAuthCodeInput!
) {
	generateCustomerTokenWithAuthCode(
	input: $input
	) {
		token
	}
}
`;
