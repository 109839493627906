






















import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import { PropType } from 'vue';
import { useProduct } from '~/composables';
import { CustomCategoryTree } from '~/modules/catalog/types';

export default defineComponent({
  name: 'AllPackagesSection',
  components: {
    SfLink,
  },
  props: {
    packagesByCategory: {
      type: Array as PropType<CustomCategoryTree[] | null>,
      default: () => [],
    },
  },
  emits: ['close-navigation'],
  setup(props, { emit }) {
    const { getProductPath } = useProduct();
    const navigationRef = ref<HTMLElement | null>(null);
    onMounted(() => {
      // Select the element
      const analysesHeaderItem = document.querySelector('#analyses-header-item');

      document.addEventListener('click', (event) => {
        if (!analysesHeaderItem.contains(event.target as Node) && !navigationRef.value.contains(event.target as Node)) {
          emit('close-navigation');
        }
      });
    });

    const onClickLink = () => {
      emit('close-navigation');
    };

    return {
      navigationRef,
      getProductPath,
      onClickLink,
    };
  },
});
