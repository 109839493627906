import locale7803b02f from '../../lang/se.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"sv","numberFormats":{"sv":{"currency":{"style":"currency","currency":"SEK","currencyDisplay":"symbol"}},"en":{"currency":{"style":"currency","currency":"SEK","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"sv","file":"se.js","iso":"se","defaultCurrency":"SEK"},{"code":"en","file":"en.js","iso":"en","defaultCurrency":"SEK"}],
  defaultLocale: "sv",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/drone/src/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: true,
  baseUrl: "http://localhost:3000",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "SE",
  normalizedLocales: [{"code":"sv","file":"se.js","iso":"se","defaultCurrency":"SEK"},{"code":"en","file":"en.js","iso":"en","defaultCurrency":"SEK"}],
  localeCodes: ["sv","en"],
}

export const localeMessages = {
  'se.js': () => Promise.resolve(locale7803b02f),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
}
