export default `
query categories(
	$pageSize: Int = 9999
	$currentPage: Int = 1
	$filters: CategoryFilterInput
) {
	categories(
		pageSize: $pageSize
		currentPage: $currentPage
		filters: $filters
	) {
		items {
			children {
				uid
				image
				name
				products {
					items {
						name
						sku
						available_in_all_clinics
						analysis_type
						url_key
					}
				}
			}
		}
	}
}
`;
