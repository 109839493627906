import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { ApplyCouponToCartMutation, Cart } from '~/modules/GraphQL/types';
import applyCouponToCartGql from '~/modules/checkout/queries/applyCouponToCart.gql';

export const applyCouponCommand = {
  execute: async (context: VsfContext, { currentCart, couponCode, customQuery = { applyCouponToCart: 'applyCouponToCart' } }) => {
    Logger.debug('[Magento]: Apply coupon on cart', {
      couponCode,
      currentCart,
    });

    const queryVariables = { input: { cart_id: currentCart.id, coupon_code: couponCode } };
    const { data, errors } = await context.$magento.api.customQuery({ query: applyCouponToCartGql, queryVariables }, customQuery);

    Logger.debug('[Result]:', { data, errors });

    return {
      updatedCart: (data as ApplyCouponToCartMutation).applyCouponToCart?.cart as unknown as Cart,
      updatedCoupon: { code: couponCode },
      errors,
    };
  },
};
